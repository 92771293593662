export const colors = [
  {
    color: "#333333",
    colorName: "Black",
  },
  {
    color: "#FFF",
    colorName: "White",
  },
  {
    color: "#474747",
    colorName: "Noble",
  },
  {
    color: "#544F50",
    colorName: "Emperor",
  },
  {
    color: "#F5E6D9",
    colorName: "Parchment",
  },
  {
    color: "#EAD16A",
    colorName: "Maize",
  },
  {
    color: "#F6E5E1",
    colorName: "Potpourri",
  },
  {
    color: "#F6B9B1",
    colorName: "Mandy's Pink",
  },
  {
    color: "#FDDDCF",
    colorName: "Target Peach",
  },
  {
    color: "#E6843B",
    colorName: "Orange",
  },
  {
    color: "#50744B",
    colorName: "Forest Shade",
  },
  {
    color: "#E1E7DF",
    colorName: "Misty",
  },
];

export const fonts = [
  {
    name: "Roboto",
    image:
      "https://cdn.customily.com/shopify/assetFiles/swatches/may-designs-main.myshopify.com/af1a5c75-f3c9-474f-bc9d-590d16cae0d0/2/15fb2438-ceed-4cef-85e0-5d05506f687e.png",
  },
  {
    name: "Roboto italic",
    image:
      "https://cdn.customily.com/shopify/assetFiles/swatches/may-designs-main.myshopify.com/af1a5c75-f3c9-474f-bc9d-590d16cae0d0/2/28092ff8-3da0-4fd4-b47b-d15c65cb2daf.png",
  },
  {
    name: "Montserrat",
    image:
      "https://cdn.customily.com/shopify/assetFiles/swatches/may-designs-main.myshopify.com/af1a5c75-f3c9-474f-bc9d-590d16cae0d0/2/5481ad74-5b5a-4b1e-9aa1-5d9dab975abc.png",
  },
  {
    name: "Sevillana",
    image:
      "https://cdn.customily.com/shopify/assetFiles/swatches/may-designs-main.myshopify.com/af1a5c75-f3c9-474f-bc9d-590d16cae0d0/2/3accc091-642e-4e90-b8d1-310b2333f3af.png",
  },
  {
    name: "Reddit Sans Condensed",
    image:
      "https://cdn.customily.com/shopify/assetFiles/swatches/may-designs-main.myshopify.com/af1a5c75-f3c9-474f-bc9d-590d16cae0d0/2/51bba9cb-2e8b-45f9-ab39-b2f87e3e0a3c.png",
  },
];

export const canvasType = {
  boardColor: [
    {
      name: "Jade",
      heading: "Jade board color",
      threeByFive: require("../assests/BoardColor/jade/jade3x5.png"),
      fourByFour: require("../assests/BoardColor/jade/jade4x4.png"),
      fourBySix: require("../assests/BoardColor/jade/jade4x6.png"),
      fiveBySeven: require("../assests/BoardColor/jade/jade5x7.png"),
    },
    {
      name: "Candy Apple",
      heading: "Candy Apple board color",
      threeByFive: require("../assests/BoardColor/candy_apple/candy_apple3x5.png"),
      fourByFour: require("../assests/BoardColor/candy_apple/candy_apple4x4.png"),
      fourBySix: require("../assests/BoardColor/candy_apple/candy_apple4x6.png"),
      fiveBySeven: require("../assests/BoardColor/candy_apple/candy_apple5x7.png"),
    },
    {
      name: "Rich Green",
      heading: "Rich Green board color",
      threeByFive: require("../assests/BoardColor/rich_green/rich_green3x5.png"),
      fourByFour: require("../assests/BoardColor/rich_green/rich_green4x4.png"),
      fourBySix: require("../assests/BoardColor/rich_green/rich_green4x6.png"),
      fiveBySeven: require("../assests/BoardColor/rich_green/rich_green5x7.png"),
    },
    {
      name: "Sapphire Blue",
      heading: "Sapphire Blue board color",
      threeByFive: require("../assests/BoardColor/Sapphire_Blue/Sapphire_Blue3x5.png"),
      fourByFour: require("../assests/BoardColor/Sapphire_Blue/Sapphire_Blue4x4.png"),
      fourBySix: require("../assests/BoardColor/Sapphire_Blue/Sapphire_Blue4x6.png"),
      fiveBySeven: require("../assests/BoardColor/Sapphire_Blue/Sapphire_Blue5x7.png"),
    },
  ],
  leatheretteColor: [
    {
      name: "Rose",
      heading: "Rose board color",
      threeByFive: require("../assests/LeatheretteColor/red/red3x5.png"),
      fourByFour: require("../assests/LeatheretteColor/red/red4x4.png"),
      fourBySix: require("../assests/LeatheretteColor/red/red4x6.png"),
      fiveBySeven: require("../assests/LeatheretteColor/red/red5x7.png"),
    },
    {
      name: "Soft Green",
      heading: "Soft Green board color",
      threeByFive: require("../assests/LeatheretteColor/SoftGreen/SoftGreen3x5.png"),
      fourByFour: require("../assests/LeatheretteColor/SoftGreen/SoftGreen4x4.png"),
      fourBySix: require("../assests/LeatheretteColor/SoftGreen/SoftGreen4x6.png"),
      fiveBySeven: require("../assests/LeatheretteColor/SoftGreen/SoftGreen5x7.png"),
    },
    {
      name: "Wine",
      heading: "Wine board color",
      threeByFive: require("../assests/LeatheretteColor/wine/wine3x5.png"),
      fourByFour: require("../assests/LeatheretteColor/wine/wine4x4.png"),
      fourBySix: require("../assests/LeatheretteColor/wine/wine4x6.png"),
      fiveBySeven: require("../assests/LeatheretteColor/wine/wine5x7.png"),
    },
    {
      name: "Winkle",
      heading: "Winkle board color",
      threeByFive: require("../assests/LeatheretteColor/winkle/winkle3x5.png"),
      fourByFour: require("../assests/LeatheretteColor/winkle/winkle4x4.png"),
      fourBySix: require("../assests/LeatheretteColor/winkle/winkle4x6.png"),
      fiveBySeven: require("../assests/LeatheretteColor/winkle/winkle5x7.png"),
    },
  ],
};

export const NumberFormatter = (num: number) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol
    : "0";
};
